.App {
  text-align: center;
  max-width: 800px;
  margin: auto;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

.custom-step-label {
  font-size: large !important;
  font-weight: 700 !important;
}

.assessment-title {
  margin-bottom: 35px;
}

#root {
  background: darkgray;
}

/* TODO Add css to impact factors for alternating backgrounds */